<template>
  <div class="cpt-mount cpt-MMC_Gimbal_P0_Pro">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">喊话播放</div>
          <div class="input-box">
            <div class="icon-box">
              <span @click="handle_play" class="el-icon-video-play"></span>
              <span @click="handle_pause" class="el-icon-video-pause"></span>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">下&nbsp;一&nbsp;曲</div>
          <div class="input-box">
            <el-select
              @change="handle_change_music"
              v-model="music"
              size="mini"
              placeholder="请选择下一曲"
            >
              <el-option :label="1" :value="1"></el-option>
              <el-option :label="2" :value="2"></el-option>
              <el-option :label="3" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">俯仰操作</div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_P0_Pro } from "../utils";
export default {
  props: {
    whachChange:{
      typeof:Number,
      default:0
    }
  },
  data() {
    return {
      music: null,
      control_speed: 3,
      joystick:null
    };
  },
  methods: {
    handle_play() {
      let buffer = MMC_Gimbal_P0_Pro.gimbal_audio_play_ctl(2);
      this.commit_directive(buffer);
    },
    handle_pause() {
      let buffer = MMC_Gimbal_P0_Pro.gimbal_audio_play_ctl(0);
      this.commit_directive(buffer);
    },
    handle_change_music() {
      let buffer = MMC_Gimbal_P0_Pro.gimbal_audio_play_next_ctl();
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      // console.log(buffer);
      this.$emit("directive", buffer);
    },
    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "down":
                this.buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_P0_Pro.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch:{
    whachChange(){
      if(this.joystick){
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>